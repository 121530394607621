import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DaysAvailableCategory } from '../../models/DaysAvailableCategory';
import { NonWorkingDays } from '../../models/NonWorkingDays';
import { ResponseDetailVehicleRequest } from '../../models/Responses/ResponseDetailVehicleRequest';
import { ResponseEmployee } from '../../models/Responses/ResponseEmployee';
import { ResponseNotifications } from '../../models/Responses/ResponseNotifications';
import { VehicleRequestType } from '../../models/VehicleRequestType';

const apiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient) { }

  servicePostNonWorkingDays(year: string, data): Observable<NonWorkingDays[]> {
    return this.http.post<NonWorkingDays[]>(`${environment.apiUrl}/calendar/non-working-days/${year}`, data);
  }

  servicePostDataEmployee(data): Observable<ResponseEmployee> {
    return this.http.post<ResponseEmployee>(`${environment.apiUrl}/employee/data`, data);
  }

  serviceGetDataUser(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/user/data`, data);
  }

  servicePostDaysAvailableEmployee(data): Observable<ResponseEmployee> {
    return this.http.post<ResponseEmployee>(`${environment.apiUrl}/days-available/employee`, data)
      .pipe(shareReplay());
  }

  servicePostRequestEmployee(data): Observable<ResponseEmployee> {
    return this.http.post<ResponseEmployee>(`${environment.apiUrl}/holiday-request/employee`, data);
  }

  servicePostNotificationEmployee(data): Observable<ResponseNotifications> {
    return this.http.post<ResponseNotifications>(`${environment.apiUrl}/holiday-request/notifications`, data);
  }

  serviceLogin(token): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/authorize/login?token=${token}`);
  }

  serviceNewAccount(token): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/authorize/new-account?token=${token}`);
  }

  servicePostCalendarByEmployee(idEmployee, data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/department/calendar/${idEmployee}`, data);
  }

  serviceGetCategories(year, data): Observable<DaysAvailableCategory[]> {
    return this.http.post<DaysAvailableCategory[]>(`${environment.apiUrl}/days-available/categories/${year}`, data);
  }

  serviceGetBlockDays(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/block-days`);
  }

  serviceSendRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/holiday-request/create-request`, data);
  }

  serviceAcceptRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/holiday-request/accepted-request`, data);
  }

  serviceRemoveRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/holiday-request/remove-ticket`, data);
  }

  serviceDeniedRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/holiday-request/deny-ticket`, data);
  }

  serviceGetAllDaysAvailableType(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/days-available-type/get-list-type`, data);
  }

  serviceGetAllDaysAvailableCategory(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/days-available-category/get-list-category`, data);
  }

  serviceCreateDaysAvailableType(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/days-available-type/create`, data);
  }
  serviceEditDaysAvailableType(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/days-available-type/edit`, data);
  }

  serviceChangeStatusDaysAvailableType(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/days-available-type/change-status`, data);
  }

  serviceHolidayGetAllEmployees(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/employee/list`, data);
  }

  serviceGetCalendarGeneral(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/calendar/list`, data);
  }

  serviceCreateCalendarGeneral(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/calendar/create`, data);
  }

  serviceGetCalendarEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/calendar-employee/list`, data);
  }

  serviceCreateCalendarEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/calendar-employee/create`, data);
  }

  serviceListNonWorkingType(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/non-working-type/list`, data);
  }

  serviceListDepartment(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/department/list`, data);
  }


  serviceListDivision(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/division/list`, data);
  }

  serviceReportHolidaysRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/report/employee-holidays`, data);
  }

  serviceExportHolidaysRequest(data): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post<any>(`${environment.apiUrl}/report/export/employee-holidays`, data, { headers });
  }

  serviceGetVehicles(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/vehicles/list-active`);
  }

  serviceUploadFileVehicles(data) {
    return this.http.post(`${environment.apiUrl}/vehicles/upload`, data);
  }

  serviceVehiclesAllRequestType(data): Observable<VehicleRequestType[]> {
    return this.http.post<VehicleRequestType[]>(`${environment.apiUrl}/vehicles/all-request-type-active`, data);
  }

  serviceVehiclesNewRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/vehicles/create-request`, data);
  }

  serviceVehiclesListRequestPendingByEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/vehicles/employee/request`, data);
  }

  serviceVehiclesGetRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/vehicles/vehicles-request`, data);
  }

  serviceVehiclesGetRequestHistoric(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/vehicles/vehicles-request-historic`, data);
  }

  serviceVehiclesExportRequest(data): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post<any>(`${environment.apiUrl}/vehicles/vehicles-request/export`, data, { headers });
  }

  serviceVehiclesExportRequestHistoric(data): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post<any>(`${environment.apiUrl}/vehicles/vehicles-request-historic/export`, data, { headers });
  }

  serviceVehiclesDetailRequest(data): Observable<ResponseDetailVehicleRequest> {
    return this.http.post<ResponseDetailVehicleRequest>(`${environment.apiUrl}/vehicles/vehicles-request/detail`, data);
  }

  serviceChangeStatusVehicleRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/vehicles/modify-request`, data);
  }

  serviceChangePermissionsEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/employee/change-group`, data);
  }

  serviceHolidayGetAllAdminEmployeesVehicles(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/vehicles/list-employee`, data);
  }

  serviceHolidayGetAllEmployeesVehicles(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/employee/list-all`, data);
  }

  serviceResolveRequest(token, data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/email/request?token=${token}`, data);
  }

  serviceResolveRequstRegisterDismiss(token, data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/account/update-request-by-token?token=${token}`, data);
  }

  serviceRemovePermissionsEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/employee/remove-group`, data);
  }

  serviceListViewEmployeesByEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/employee-view/list`, data);
  }

  serviceUpdateListViewEmployeesByEmployee(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/employee-view/list/update`, data);
  }

  serviceVehiclesExport(data): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post<any>(`${environment.apiUrl}/vehicles/export`, data, { headers });
  }

  serviceConfirmationMessageRequest(token): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/email/confirmation-message?token=${token}`);
  }

  //ACCOUNT

  serviceGetAllEmployees(){
    return this.http.get<any>(`${environment.apiUrl}/employees`);
  }

  serviceGetAllCompanies(){
    return this.http.get<any>(`${environment.apiUrl}/companies`);
  }

  serviceGetAllDepartments(){
    return this.http.get<any>(`${environment.apiUrl}/departments`);
  }

  serviceGetAllDivisions(){
    return this.http.get<any>(`${environment.apiUrl}/divisions`);
  }

  serviceAccountRequests(filters){
    const urlFilters = new URLSearchParams(filters).toString()
    return this.http.get<any>(`${environment.apiUrl}/account/requests?${urlFilters}`);
  }

  public serviceAccountGetRequestDetail(type, id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/requests/${type}/${id}`, );
  }

  public serviceNewEmployeeRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/account/register_request`, data);
  }

  public serviceRemoveEmployeeRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/account/dismiss_request`, data);
  }

  public serviceModifyEmployeeRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/account/modify_request`, data);
  }


  public serviceUpdateRequest(user, type, id, status, reason?): Observable<any> {

    return this.http.post<any>(`${environment.apiUrl}/account/requests/${type}/${id}`, {user, status, reason});
  }

  public serviceAccountExportRequests(filters = {}): Observable<any> {
    return this.http.get(`${environment.apiUrl}/account/requests/export`, { params: filters });
  }

  serviceReportHomeworkRequest(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/report/employee-homework`, data);
  }

  serviceExportHomeworkRequest(data): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post<any>(`${environment.apiUrl}/report/export/employee-homework`, data, { headers });
  }

  public serviceAddNewCompany(data): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/account/add_company`, data);
  }

  
}
